<template>
  <div class="authentication">
    <Authentication />
  </div>
</template>

<script>
import Authentication from '@/components/Authentication.vue'
export default {
  components: {
    Authentication
  },
  data() {
    return {}
  }
}
</script>

<style scoped>
.authentication{
  margin-top: 100px;
}
</style>
